import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.SUBSCRIPTION,
  state: states.SMS_AUTH_REQUIRED,
  stateContext: constants.state_context.PAYMENT_AUTH,
  frozenConfig: {
    payments: {
      contactMfaCounter: 0,
    },
    widget: {
      entityIdSelected: 'cl_banco_scotiabank', // optional
    },
  },
};
